.recipe {
    background-color: #f7f5f3;
    color: #1d201f;
    display: flex;
    justify-content: flex-start;
    gap: 1em;
    padding: 1em;
    border-radius: 10px;
    width: 90%;
    margin: 1em auto 0;
    box-shadow: 9px 9px 0px rgb(247, 245, 243, .08);
}

@media screen and (max-width: 500px) {
    .recipe {
        flex-direction: column;
    }

    .recipe-img img {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
    }
}

.recipe-img .description {
    flex: 1;
}

.recipe-img img {
    object-fit: cover;
    border-radius: 10px;
    width: 100%;
}